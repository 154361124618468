.comment_input{
    display: flex;
    align-items: center;
    background-color: #fff500;
}
.comment_input input{
    background: #fff500;
    border: none;
    outline: none;
    flex: 1;
    overflow: auto;
    padding: 5px;
    border-radius: 10px;
}

.comment_input .postBtn{
    border: none;
    outline: none;
    background: #27004b;
    color: #fff500;
    font-weight: 600;
    padding: 0.8%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 7px;
}
.comment_input .dropdown-menu{
    transform: translate3d(-120px, -200px, 0px) !important;
}

/* ---------- Comments ---------- */
.comment_display{
    padding: 10px 25px;
}
.comment_card .comment_content{
    background: white;
    padding: 8px;
    margin-top: 8px;
    border-radius: 10px;
    border-top-left-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment_card .comment_content .readMore{
    cursor: pointer;
    color: crimson;
}

.comment_card .comment_content .nav-item{
    cursor: pointer;
    opacity: 0;
}
.comment_card .comment_content:hover .nav-item{
    opacity: 1;
}
.comment_card .comment_content .dropdown-menu{
    position: absolute;
    right: 0;
    left: inherit;
}
.comment_card .comment_content .dropdown-item{
    display: flex;
    margin: 10px 0;
}
.comment_card .comment_content textarea{
    width: 100%;
    border: none;
    outline: none;
}

/* ---------- Media Query for Smaller Screens ---------- */
@media (max-width: 766px) {
    .comment_input input{
        width: 100%;
    }

    .comment_input .postBtn{
        border: none;
        outline: none;
        background: #27004b;
        color: #fff500;
        font-weight: 600;
        padding: 1.5%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        border-radius: 7px;
    }
}