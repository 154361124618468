.suggestion-header {
    position: fixed;
    opacity: 0;
    animation: fadeRight 0.3s forwards;
    animation-delay: 0.3s;
    width: 100%;
    margin-left: 28%;
    margin-bottom: 30%;
}

.suggestion {
    height: 75vh;
    width: 50%;
}

.suggestion_name h5 {
    color: #27004b;
    font-size: 200%;
    margin-bottom: 2%;
}

.suggestion_reload {
    cursor: pointer;
    margin-right: 60%;
    margin-left: 5%;
    margin-bottom: 1%;
    font-size: 170%;
}

.scrollbars_right {
    height: 65vh;
    width: 22vw;
}

@keyframes fadeDown {
    0% {
        transform: translateY(-1rem);
    }
  
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }

  @keyframes fadeRight {
    0% {
        transform: translateX(4rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* --------- Responsive ------ */

@media (max-width: 766px){
    .suggestion-header {
        position: fixed;
        opacity: 0;
        animation: fadeRight 0.3s forwards;
        animation-delay: 0.3s;
        width: 100%;
        height: 100vh;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 30%;
        background-image: url('../images/burger4_01-scaled.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        padding: 10px;
        margin-top: 3%;
        margin-bottom: 0;
        padding-bottom: 10%;
    }
    
    .suggestion {
        width: 100%;
        background-color: #f1f1f1;
        border-radius: 10px;
        box-shadow: 5px 5px 10px #27004b;
        height: 70vh;
    }

    .suggestion_name {
        background-color: #27004b;
        color: #fff500;
        border-radius: 50px;
        padding-left: 5%;
        box-shadow: 1px 1px 10px #27004b;
    }

    .suggestion_name h5 {
        font-size: 15px;
        margin-bottom: 3%;
        margin-top: 3%;
        border-radius: 10px;
        color: #fff500;
    }

    .suggestion_reload {
        cursor: pointer;
        margin-right: 7%;
        margin-left: 5%;
        margin-bottom: 1%;
        font-size: 15px;
        margin-bottom: 2%;
        margin-top: 3%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .suggestion_name h5 {
        font-size: 150%;
    }

    .suggestion_reload {
        font-size: 150%;
    }
}