@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Barcode+39+Text&family=Pacifico&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Barcode+39+Text&family=Pacifico&family=Righteous&family=Yanone+Kaffeesatz:wght@400&display=swap');

.header {
    width: 100%;
    min-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    /* background-color: #31005d; */
    background-color: #27004b;
    box-shadow: 1px 1px 10px black;
    border: none;
    text-decoration: none;
    margin-bottom: 0;
}

.header .navbar {
    width: 100%;
    height: 100%;
}

.small-avatar {
    width: 27px;
    height: 27px;
}

.header .logo {
    text-decoration: none;
}

.header .logo h1 {
    font-size: 1.6rem;
    color: #fff500;
    font-family: 'Dancing Script', cursive;
    font-family: 'Libre Barcode 39 Text', cursive;
    font-family: 'Pacifico', cursive;
    font-family: 'Righteous', cursive;
}

.header .imgLogo {
    height: 50px;
    width: 60px;
    margin-left: 10px;
    margin-right: -5px;
}

/* ----------- Header Menu ---------- */

/* .header .menu li {
    opacity: 0.5;
} */

.header .menu li.active {
    opacity: 1;
}

.header .menu .material-icons {
    font-size: 30px;
    color: #fff500;
}

.header .menu .dropdown-menu {
    position: absolute;
    left: inherit;
    right: 0;
    background-color: #27004b;
}

.header .menu .dropdown-menu:hover {
    position: absolute;
    left: inherit;
    right: 0;
    color: #fff500;
}

.header .menu .dropdown-menu .dropdown-divider {
    border: none;
    background-color: #27004b;
}

.header .menu .dropdown-menu .dropdown-divider:hover {
    border: none;
    /* background-color: #1b043f; */
    background-color: #42007c;
}

.header .menu .dropdown-menu .dropdown-item {
    color: white;
    background-color: #27004b;
}

.header .menu .dropdown-menu .dropdown-item:hover {
    color: #fff500;
    background-color: #440e74;
}

.header .menu .dropdown-menu .dropdown-item span {
    padding-right: 2%;
}

.header .menu label {
    cursor: pointer;
}

.header .menu .notify_length {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #27004b;
    font-weight: bold;
    font-size: 10px;
}

/* ---------- Header Search -------- */
.header .search_form {
    position: relative;
    border-radius: 50px;
    /* margin-left: -45%; */
}

.header .search_form #search {
    background: #fafafa;
    border: none;
    min-width: 250px;
    width: 100%;
    height: 30px;
    outline: none;
    text-indent: 5px;
    border-radius: 50px;
    font-weight: 500;
    border-radius: 50px;
}

.header .search_form .search_icon {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    pointer-events: none;
}

.header .search_form .search_icon .material-icons {
    font-size: 15px;
    transform: translateY(3px);
}

.header .search_form .close_search {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    font-weight: 900;
    color: #27004b;
}

.header .search_form .users {
    position: absolute;
    width: 100%;
    min-width: 250px;
    /* background: #fafafa; */
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-top: 0%;
    border: none;
    border-radius: 5px;
    background-color: #27004b;
}

.header .search_form .loading {
    position: absolute;
    top: 60%;
    right: 8%;
    width: 15px;
    height: 15px;
    transform: translateY(-90%);
}

/* .header .menu .dropdown-menu {
    position: absolute;
    bottom: 100%;
    right: 0;
    left: inherit;
} */


/* Media Query for Mobile Devices */
/* @media (max-width: 766px) {
    .header .search_form {
        margin-left: 0%;
        bottom: 0;
        left: 0;
        position: relative;
        background-color: #31005d;
        color: white;
        border-radius: 0%;
        text-align: center;
        overflow: none;
    }

    .header .menu .material-icons {
        font-size: 25px;
        color: yellow;
    }

    .header .menu {
        margin-left: 0%;
        bottom: 0;
        left: 0;
        position: fixed;
        background-color: #31005d;
        border-radius: 0%;
        text-align: center;
        width: 100%;
        overflow: auto;
    }

    .header .search_form .users {
        position: absolute;
        width: 100%;
        min-width: 250px;
        background: #fafafa;
        color: white;
        max-height: calc(100vh - 150px);
        overflow: auto;
        margin-top: 9px;
        border-radius: 10px;
    }

    .header .search_form #search {
       
        background-color: #31005d;
        color: #fafafa;
        height: 30px;
    }

    .header .search_form .search_icon {
        left: 20%;
    }

    .header .search_form .close_search {
        position: absolute;
        top: 0;
        right: 10%;
        margin-top: 3%;
        cursor: pointer;
        font-weight: 900;
        color: #31005d;
    }
} */


/* ---------- Mobile Specific Adjustments ---------- */
@media (max-width: 766px) {
    .header {
        flex: 1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        box-shadow: none;
        margin-bottom: 0;
        background-color: #27004b; /* Ensure background covers content behind */
    }

    body {
        padding-top: 40px; /* Adjust this padding to match the header height */
    }

    .header .menu {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #27004b;
        text-align: center;
        border-radius: 0;
        z-index: 10;
        left: 0;
        padding-top: 10px;  /* Adjust padding as needed */
        padding-bottom: 10px;
        height: auto;
        display: flex;  /* Use flexbox for layout */
        justify-content: space-around;  /* Distribute items with equal spacing */
        align-items: center;  /* Center items vertically */
        box-sizing: border-box;
    }
    
    .header .menu li {
        list-style: none;  /* Remove bullet points */
        margin: 0;  /* Remove default margins */
        padding: 0 10px;  /* Optional: Add some horizontal padding */
        flex: none;  /* Prevent menu items from stretching */
        opacity: 1;
        text-align: center;  /* Center text inside items */
    }    

    .header .menu .dropdown-menu {
        bottom: 80%; /* Move dropdown upwards */
        top: auto;    /* Remove top position */
        right: 0;
        left: inherit;
        z-index: 100; /* Ensure dropdown is above other content */
        border: none;
    }

    .header .search_form {
        margin-left: 0%;
        bottom: 0;
        left: 0;
        position: relative;
        background-color: #27004b;
        color: white;
        border-radius: 0%;
        text-align: center;
        overflow: none;
    }

    .header .search_form #search {
        background-color: #27004b;
        color: #fafafa;
        height: 30px;
    }

    .header .search_form .search_icon {
        left: 17%;
    }

    .header .search_form .close_search {
        position: absolute;
        top: 0;
        right: 10%;
        margin-top: 1%;
        cursor: pointer;
        font-weight: 900;
        color: #fff500;
    }

    .header .search_form .loading {
        display: none;
    }

    .avatar {
        border-radius: 50%; /* Ensure the avatar is circular */
        object-fit: cover; /* Ensure image fits well inside the avatar */
    }
    
    .large-avatar {
        width: 60px;
        height: 60px;
    }
    
    .small-avatar {
        width: 27px;
        height: 27px;
    }
    
}