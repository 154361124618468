.settings-container {
    display: flex;
    width: 100%;
    height: 100vh; /* Ensure full viewport height */
    font-family: Arial, sans-serif;
    position: relative; /* Change to relative so scrolling works properly */
    background-color: #f1f1f1;
}

.settings-menu {
    width: 20%;
    background-image: url('../images/burger4_01-scaled.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    padding: 10px;
    border-right: 1px solid #ccc;
    min-height: 100%; /* Ensure the menu covers full height */
    position: fixed; /* Keep menu fixed */
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1; /* Set a lower z-index than header */
    overflow-y: auto; /* Allow vertical scrolling in the menu */
    padding-top: 5%;
    box-shadow: 5px 5px 10px #27004d;
}

.menu-option {
    display: block;
    width: 90%;
    padding: 15px;
    font-size: 16px;
    text-align: left;
    background-color: transparent;
    border: none;
    margin-left: 5%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    background-color: #f1f1f1e8;
    margin-bottom: 2%;
    box-shadow: 5px 5px 10px rgba(39, 0, 75, 0.6);
    font-weight: bold;
    margin-top: 3%;
}

.menu-option:hover {
    background-color: rgba(39, 0, 75, 0.8);
    box-shadow: 8px 8px 15px rgba(39, 0, 75, 0.6);
    color: white;
    border: none;
    font-weight: bold;
}

.menu-option.active {
    background-color: rgba(39, 0, 75, 0.8);
    font-weight: bold;
    color: #fff500;
    border: none;
}

/* Accordion Content */
.accordion-content {
    display: none;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-top: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.accordion-content.show {
    display: block;
    max-height: 1000px; /* or any large value */
}

.about_detail {
    margin-left: 25%;
    margin-top: 2%;
    margin-right: 2%;
    margin-bottom: 3%;
}

.about_detail img {
    width: 40%;
    margin-bottom: 3%;
    margin-left: 0%;
}

.terms_detail {
    margin-left: 25%;
    margin-top: 2%;
    margin-right: 2%;
    margin-bottom: 3%;
}

.privacy_detail {
    margin-left: 25%;
    margin-top: 2%;
    margin-right: 2%;
    margin-bottom: 3%;
}

@media (max-width: 768px) {
    .settings-container {
        flex-direction: column;
    }

    .settings-menu {
        width: 100%;
        min-height: auto;
        padding: 0;
        border: none;
        position: relative; /* Adjust for mobile layout */
        height: 100%; /* Ensure menu takes up full height on mobile */
        overflow-y: auto; /* Allow vertical scrolling */
        padding-top: 16%;
        height: 100%;
    }
    
    .settings-content {
        display: none;
        width: 100%;
        padding: 10px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 1000vh; /* Allow content to scroll when it exceeds 70% of the viewport height */
    }

    /* Adjust menu options for mobile */
    .menu-option {
        width: 90%;
        margin: 0;
        margin-bottom: 3%;
        margin-left: 5%;
        cursor: pointer;
        box-shadow: 5px 5px 10px #27004d;
        background-color: #f1f1f1;
    }

    /* Accordion Content */
    .accordion-content {
        padding: 10px;
        background-color: #f1f1f1;
        border-radius: 5px;
        margin-top: 10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        margin-bottom: 5%;
    }

    .menu-option.active + .accordion-content {
        display: block;
        width: 90%;
        margin-left: 5%;
        max-height: 100000vh; /* Limit the max-height for smooth transition */
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .settings-container {
        display: flex;
        width: 100%;
        height: 100vh; /* Ensure full viewport height */
        font-family: Arial, sans-serif;
        position: relative; /* Change to relative so scrolling works properly */
    }
    
    .settings-menu {
        padding-top: 7%;
        width: 25%;
    }
    
    .menu-option {
        display: block;
        width: 90%;
        padding: 15px;
        font-size: 16px;
        text-align: left;
        background-color: transparent;
        border: none;
        margin-left: 5%;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 10px;
        background-color: #f1f1f1e8;
        margin-bottom: 2%;
        box-shadow: 5px 5px 10px rgba(39, 0, 75, 0.6);
        font-weight: bold;
        margin-top: 7%;
    }

    .about_detail {
        margin-left: 30%;
        margin-top: 2%;
        margin-right: 2%;
        margin-bottom: 3%;
    }
    
    .terms_detail {
        margin-left: 30%;
        margin-top: 2%;
        margin-right: 2%;
        margin-bottom: 3%;
    }
    
    .privacy_detail {
        margin-left: 30%;
        margin-top: 2%;
        margin-right: 2%;
        margin-bottom: 3%;
    }
}