@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.myselfDetail {
    text-align: center;
    background-color: #27004b;
    border-radius: 10px;
    padding: 5%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    box-shadow: 2px 2px 20px black;
}

.img {
    height: 50%;
    width: 100%;
}

.title {
    font-size: 30px;
    color: #27004b;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.subTitle {
    font-size: 17px;
    color: red;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.description {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 5%;
}

.b1 {
    font-size: 25px;
    color: red;
}

.follow {
    font-family: 'Lobster', cursive;
}

.followMe {
    text-align: center;
}

.followMe a {
    text-decoration: none;
    margin-right: 12%;
}

.followMe .facebook {
    margin-left: 10%;
}

.copyright {
    left: 0;
    bottom: 0%;
    height: 10%;
    width: 100%;
    padding: 2%;
    color: white;
    /* background-color: #31005d; */
    font-size: 15px;
    position: fixed;
    opacity: 0;
    animation: fadeUp 1s forwards;
    animation-delay: 1s;
}

.copyright .myself {
    text-decoration: none;
    text-align: center;
    color: #27004b;
    background-color: #fff500;
    box-shadow: 2px 2px 20px #fff500;
    border-radius: 5px;
    padding: 0.3%;
    border: none;
    cursor: pointer;
    animation: blink 1s linear infinite;
}

.copyright .myself:hover,
.copyright .myself:active {
    animation: none;
}

.copyright .cyberengine {
    text-decoration: none;
    text-align: center;
    color: #27004b;
    background-color: #fff500;
    box-shadow: 2px 2px 20px #fff500;
    border-radius: 5px;
    padding: 0.3%;
    border: none;
    cursor: pointer;
    animation: blink 1s linear infinite;
}

.copyright .cyberengine:hover,
.copyright .cyberengine:active {
    animation: none;
}

@media only screen and (max-width: 766px) {
    .copyright {
        text-align: center;
        bottom: 0%;
        position: fixed;
    }

    .myselfDetail {
        width: 100%;
        bottom: 0%;
        margin-left: 0%;
        margin-right: 0%;
    }

    .followMe a {
        text-decoration: none;
        margin-right: 12%;
    }

    .followMe .facebook {
        margin-left: 8%;
    }

    .b1 {
        font-size: 20px;
        color: red;
    }
}