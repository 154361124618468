.reactions{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 150px;
    overflow-y: auto;
    padding: 1px;
    width: 200px;
}

.reactions span {
    cursor: pointer;
    font-size: 20px;
}

.scrollable {
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}