* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#theme {
  display: none;
}

#theme:checked ~ * {
  filter: invert(1);
}

.App {
  width: 100%;
  min-height: 100vh;
  background: white;
  background-image: url('../images/burger4_01-scaled.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.status {
  margin-left: 5%;
  margin-right: 5%;
  opacity: 0;
  animation: fadeDown 0.5s forwards;
  animation-delay: 0.5s;
}

.main {
  max-width: 100%;
  width: 100%;
  /* min-height: 100vh; */
  margin: auto;
  margin-top: 3.5%;
}

img {
  object-fit: cover;
}

.ContactUs {
  width: 100%;
  height: 100vh;
  opacity: 0;
  animation: fadeUp 0.3s forwards;
  animation-delay: 0.3s;
  margin-bottom: 3%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: #0078da; */
  background: #27004b;
}

/* -------- AUTH ---------- */
@import url("./auth.css");

/* -------- LOADING ---------- */
@import url("./loading.css");

/* -------- Header ---------- */
@import url("./header.css");

/* -------- Avatar ---------- */
@import url("./avatar.css");

/* -------- Header ---------- */
@import url("./header.css");

/* -------- Profile ---------- */
@import url("./profile.css");

/* -------- Home ---------- */
@import url("./home.css");

/* -------- Status Modal ---------- */
@import url("./status_modal.css");

/* -------- Comments ---------- */
@import url("./comments.css");

/* -------- Post Thumb ---------- */
@import url("./post_thumb.css");

/* -------- Message---------- */
@import url("./message.css");

/* -------- Icons---------- */
@import url("./icons.css");

/* -------- Call Modal---------- */
@import url("./call_modal.css");


@keyframes fadeUp {
  0% {
      transform: translateY(4rem);
  }

  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeRight {
  0% {
      transform: translateX(4rem);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeLeft {
  0% {
      transform: translateX(-4rem);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
}


@keyframes fadeDown {
  0% {
      transform: translateY(-1rem);
  }

  100% {
      opacity: 1;
      transform: translateY(0);
  }
}


/* --------- Responsive ------ */
@media (max-width: 766px){
  .header .navbar .logo{
    display: inline;
  }

  .header .menu{
      width: 100%;
      padding-bottom: 5px;
      height: 40px;
  }

  /* .main {
    background-image: url('../images/burger4_01-scaled.jpg');
    background-attachment: fixed;
    background-size: cover;    
    background-position: center;
  } */

  .App {
    background-image: none;
    /* background-color: #27004b83; */
    background-color: #f1f1f1;
  }

  .header .menu .navbar-nav{
      display: flex;
      width: 100%;
      left: 0;
      /* bottom: 0; */
      /* position: fixed; */
      /* background-color: #30115d; */
      justify-content: space-around;
  }
  
  .header .search_form{
      margin-top: 1%;
      width: 100%;
  }

  .header .logo{
      margin: 0;
  }

  .header .logo img{
    height: 40px;
    width: 40px;
}

  .header .logo h1{
      font-size: 1.2rem;
  }
  
  .message{
    height: calc(100vh - 100px);
  }

  .right_mess,
  .left_mess{
    display: none;
  }

  .status{
    padding: 20px 10px;
    margin-left: -20%;
    width: 100%;
  }

  .ContactUs {
    width: 100%;
    height: 100vh;
    opacity: 0;
    animation: fadeUp 0.3s forwards;
    animation-delay: 0.3s;
    margin-top: 5%;
    margin-bottom: 20%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .mmt-2 .scrollbars_right {
      height: 75vh;
      width: 25vw;
  }

  .main {
    margin-top: 5%;
  }
}