.contact-form-container {
    background-image: url('../images/burger4_01-scaled.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 0;
    margin-bottom: 0;
}

.form-control {
    background-color: white;
    color: black;
    border: 3px solid #27004b;
    border-radius: 10px;
    padding: 2%;
}

.form-control:focus {
    background-color: #fff500;
    border-radius: 2px solid #21004b;
}

label {
    color: #27004b;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

input, textarea {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.popup-message {
    margin-top: 3%;
    margin-bottom: 3%;
    text-align: center;
}

.popup-message .success-popup {
    color: white;
    background-color: green;
    border-radius: 10px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    padding: 1%;
}

.form {
    background-color: #f1f1f1ee;
    padding: 20px;
    border-radius: 10px;
    max-width: 700px;
    width: 100%;
    box-shadow: 1px 1px 10px #27004b;
    margin-top: -2%;
}

.form input {
    cursor: pointer;
}

@media (max-width: 766px){
    .contact-form-container {
        width: 100%;
        margin-top: -6%;
    }

    .form {
        margin-top: 10%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .form {
        margin-top: 3%;
    }
}