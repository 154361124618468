.ads {
    background-color: #27004bee;
    /* background-color: rgb(63, 0, 117); */
    height: 100vh;
    width: 20%;
    position: fixed;
    padding-bottom: 3%;
    left: 0;
    opacity: 0;
    animation: fadeLeft 0.3s forwards;
    animation-delay: 0.3s;
}

.scrollbars {
    width: 100%;
    height: 100%;
}

h1 {
    font-family: 'Lobster', cursive;
    margin-left: 45%;
}

.h5 {
    padding-top: 8%;
    color: white;
    text-align: center;
}

.hr {
    color: #fff500;
    width: 100%;
    margin-left: 0%;
}


.filter-ads {
    background-color: #27004b;
    height: 100vh;
    width: 22%;
    margin-top: -6%;
    position: fixed;
    left: 78%;
}

.filter-ads option {
    color: white;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.scrollbars-ads {
    width: 100%;
    height: 100%;
}

.filter-ads img {
    margin-bottom: 8%;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeUp {
    0% {
        transform: translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeRight {
    0% {
        transform: translateX(4rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeLeft {
    0% {
        transform: translateX(-4rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes fadeDown {
    0% {
        transform: translateY(-1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scale {
    0% {
        transform: scale(2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #fff500;
    }

    50% {
        box-shadow: 0 0 0 1rem #27004b;
    }

    90% {
        box-shadow: 0 0 0 0 #27004b;
    }
}

@keyframes afterClickedPulse {
    0% {
        box-shadow: 0 0 0 0 rgb(1, 97, 241);
    }

    50% {
        box-shadow: 0 0 0 1rem #27004b;
    }

    90% {
        box-shadow: 0 0 0 0 #27004b;
    }
}

@media (max-width: 766px){
    .ads {
        display: none;
    }
  }