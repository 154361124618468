/* --------- Status ---------- */
.myStatus{
    background: #f1f1f1;
    box-shadow: 1px 1px 10px #27004b;
    padding: 20px;
    padding-left: 15px;
    border-radius: 5px;
    border: none;
    width: 80%;
    margin-left: 10%;
    opacity: 0;
    animation: fadeUp 0.7s forwards;
    animation-delay: 0.7s;
}

.final_post {
    margin-top: -3%;
}

.logo .imgLogo {
    margin-top: -0.5%;
}

.myStatus .statusBtn{
    background: #ddd;
    border: none;
    outline: none;
    border-radius: 30px;
    color: #555;
    font-weight: bold;
    padding: 0 15px;
    text-align: left;
    margin: 0 15px;
}

.myStatus .statusBtn:hover{
    background: #fff500;
}

/* ------------ Posts ---------- */
.posts .card{
    box-shadow: 1px 1px 10px #27004b;
    width: 80%;
    margin-left: 10%;
    opacity: 0;
    border: none;
    animation: fadeUp 0.5s forwards;
    animation-delay: 0.5s;
    background-color: #f1f1f1;
}

/* --------- Post Card Header --------- */
.posts .card_header{
    display: flex;
    justify-content: space-between;
    align-items: left;
    cursor: pointer;
    padding: 20px;
    height: 70px;
}

.posts .card_header .card_name{
    transform: translateY(0px);
}

.posts .card_header .dropdown-menu{
    position: absolute;
    right: 0;
    left: inherit;
}

.posts .card_header .dropdown-item{
    display: flex;
    margin: 5px 0;
}

/* ---------- Post Card Body ---------- */
.posts .card_body-content{
    padding: 0 25px;
    margin-bottom: -5px;
}

.posts .card_body-content .readMore{
    color: crimson;
    cursor: pointer;
}

/* ---------- Post Card Footer ---------- */
.posts .card_icon_menu{
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
    margin-bottom: -2.5%;
}

.posts .card_icon_menu i{
    font-size: 28px;
    margin: 10px;
}

.posts .card_icon_menu img{
    width: 28px;
    height: 28px;
    transform: translate(100px, -40px);
    opacity: 0.9;
}

.mmt-2 {
    position: fixed;
    opacity: 0;
    animation: fadeRight 0.3s forwards;
    animation-delay: 0.3s;
    height: 100vh;
    padding-left: 2%;
    background-color: #f1f1f1f6;
}

.mmt-2 .scrollbars_right {
    height: 100vh;
    width: 25vw;
}

/* ------------PostsDetails ------ */

.postDetails .cardshow{
    box-shadow: 0 0 4px #ddd;
    width: 60%;
    margin-left: 7%;
}

@keyframes fadeDown {
    0% {
        transform: translateY(-1rem);
    }
  
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }

  @keyframes fadeRight {
    0% {
        transform: translateX(4rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* ------------Posts View ------ */
.posts_view .card{
    box-shadow: 1px 1px 10px #27004b;
    width: 80%;
    margin-left: 10%;
    opacity: 0;
    border: none;
    animation: fadeUp 0.5s forwards;
    animation-delay: 0.5s;
}

/* --------- Post Card Header --------- */
.posts_view .card_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    height: 70px;
    margin-bottom: -5px;
}

.posts_view .card_header .card_name{
    transform: translateY(0px);
}

.posts_view .card_header .dropdown-menu{
    position: absolute;
    right: 0;
    left: inherit;
}

.posts_view .card_header .dropdown-item{
    display: flex;
    margin: 5px 0;
}

/* ---------- Post Card Body ---------- */
.posts_view .card_body-content{
    padding: 0 15px;
    margin-top: -10px;
    margin-bottom: 0px;
}

.posts_view .card_body-content .readMore{
    color: crimson;
    cursor: pointer;
}

/* ---------- Post Card Footer ---------- */
.posts_view .card_icon_menu{
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
}

.posts_view .card_icon_menu i{
    font-size: 28px;
    margin: 10px;
}

.posts_view .card_icon_menu img{
    width: 28px;
    height: 28px;
    transform: translate(100px, -40px);
    opacity: 0.9;
}

/* ------------PostsDetails ------ */

.postDetails .cardshow{
    box-shadow: 0 0 4px #ddd;
    width: 60%;
    margin-left: 7%;
}

.position img {
    margin-bottom: -100px;
    padding-right: 8%;
}


/* --------- Privacy & Policy ------- */

.privacy_policy b {
    color: #27004b;
}

.privacy_policy ul {
    margin-left: 5%;
}

/* --------- Terms & Condition ------- */

.terms_condition {
    margin-left: 5%;
    margin-right: 5%;
}

.terms_condition h1,
.terms_condition h2{
    color: #27004b;
}

/* ---------- Load More Button ---------- */

.loadMore {
    background-color: #27004b;
    color: #fff500;
    padding: 1.5%;
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 20px #27004b;
    margin-top: 5%;
    margin-bottom: 12%;
    width: 80%; /* Set the width */
    font-weight: bold;
    display: block; /* Ensures the button behaves like a block element */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}

.loadMore:hover {
    background-color: #fff500;
    color: #27004b;
    font-weight: bold;
    font-size: large;
}


/* --------- Responsive Styles for Mobile -------- */

@media (max-width: 766px) {
    /* General Styles for Posts */
    .posts .card {
        margin: 0 auto;
        position: relative;
        width: 100%;
        margin-left: -22%;
        box-sizing: border-box;
        box-shadow: 1px 1px 10px black;
    }

    /* Logo Adjustments */
    .logo .imgLogo {
        margin-left: 0;
    }

    /* Card Header */
    .posts .card_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; /* Changed for better alignment */
        cursor: pointer;
        padding: 15px;
        height: 60px;
    }

    /* Post Title Positioning */
    .position h2 {
        font-size: 20px;
        text-align: center;
    }

    /* Hide Marginal Elements */
    .mmt-2 {
        display: none;
    }

    /* Status Section */
    .myStatus {
        background: #f1f1f1;
        box-shadow: 1px 1px 10px black;
        padding: 15px;
        border-radius: 5px;
        width: 100%;
        margin-left: -22%;
        animation: fadeUp 0.5s forwards;
    }

    .myStatus .statusBtn {
        background: #ddd;
        border: none;
        border-radius: 30px;
        color: #555;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 15px;
        margin-left: 10px;
    }

    /* Load More Button */
    .loadMore {
        background-color: #27004b;
        color: #fff500;
        margin-top: 10%;
        margin-bottom: 20%;
        padding: 3%;
        border: none;
        border-radius: 10px;
        box-shadow: 2px 2px 20px #27004b;
        width: 100%;
        font-weight: bold;
    }

    /* Card Body Content */
    .posts .card_body-content {
        padding: 0 15px;
        margin-bottom: -5px;
    }

    /* Final Post Adjustments */
    .final_post {
        margin-top: -10%;
    }

    /* Posts View Section */
    .posts_view {
        margin-top: 13%;
        padding-bottom: 5%;
        margin-bottom: 8%;
    }

    .posts_view .card {
        box-shadow: none;
        width: 95%;
        opacity: 0;
        border: none;
        animation: fadeUp 0.5s forwards;
        animation-delay: 0.5s;
        margin-left: 2.5%;
    }

    /* Icon Menu Adjustments */
    .posts .card_icon_menu {
        margin-bottom: -10%;
    }

    .posts .card_icon_menu img,
    .posts_view .card_icon_menu img {
        transform: translate(100px, -46px);
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .mmt-2 .scrollbars_right {
        height: 75vh;
        width: 25vw;
    }
}