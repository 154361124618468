.GetTheApp {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    /* background-color: #27004b; */
    background-image: url('../images/burger4_01-scaled.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    margin-top: -2%;
}

.title {
    color: #27004b;
    margin-bottom: 2%;
    /* text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    font-size: 50px;
    margin-bottom: -4%; */
}

.description {
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 1px 1px 10px #27004b;
}

.description a {
    text-decoration: none;
}

.description_2 {
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-top: 2%;
}

img {
    /* margin-left: 2%; */
    margin-right: 2%;
    margin-top: 2%; 
    height: 15%;
    width: 15%;
    cursor: pointer;
}

@media (max-width: 766px){
    .title {
        margin-top: 0%;
    }

    img {
        /* margin-left: 2%; */
        margin-top: 5%;
        height: 50%;
        width: 60%;
    }

    .description {
        margin-bottom: 5%;
    }

    .description_2 {
        margin-left: 2%;
        margin-right: 2%;
        margin-bottom: 1%;
        margin-top: 10%;
    }

    .GetTheApp {
        padding-top: 25%;
        padding-bottom: 20%;
        margin-top: -14%;
    }
}