.supper-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.big-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.medium-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.small-avatar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}