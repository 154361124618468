.status_modal{
    position: fixed;
    top:0;
    left: 0;
    background: #0008;
    z-index: 4;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.status_modal form{
    max-width: 450px;
    width: 100%;
    background: white;
    margin: 30px auto;
    padding: 20px;
    border-radius: 5px;
}
.status_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    transform: translateY(-13px);
} 
.status_header span{
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
    transform: translateY(-5px);
}
.status_body textarea{
    width: 100%;
    min-height: 150px;
    border: none;
    outline: none;
    resize: none;
}
.status_body .input_images{
    display: flex;
    justify-content: center;
    margin: 10px 0;
}
.status_body .input_images i{
    font-size: 2rem;
    cursor: pointer;
}
.status_body .input_images .file_upload{
    overflow: hidden;
    margin: 0 10px;
    position: relative;
}
.status_body .input_images .file_upload #file{
    position: absolute;
    top:0;
    left: 0;
    opacity: 0;
}
.status_body .show_images{
    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;
}
.status_body .show_images #file_img{
    position: relative;
    width: 100%;
    height: 100%;
}
.status_body .show_images #file_img img,
.status_body .show_images #file_img video{
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 100px;
}
.status_body .show_images #file_img span{
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;
}
.status_body .stream span{
    position: absolute;
    top: -10px;
    right: 5px;
    color: crimson;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
}
.status_body .dropdown-menu{
    transform: translate3d(-153px, -190px, 0px) !important;
}

@media (max-width: 766px){
    .status_modal{
        position: fixed;
        top:0;
        left: 0;
        background: #0008;
        z-index: 4;
        width: 100%;
        height: 100vh;
        overflow: auto;
    }

    .status_modal form{
        max-width: 450px;
        width: 96%;
        background: white;
        margin: 30px auto;
        padding: 20px;
        border-radius: 5px;
        margin-top: 27%;
    }
}